// gatsby-browser.jsとgatsby-ssr.js
import React from 'react';
import { PlayerProvider } from './src/contexts/PlayerContext';
import Layout from './src/components/layout';
import "@fortawesome/fontawesome-svg-core/styles.css";
import './src/styles/global.css'
import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapPageElement = ({ element }) => (
  <PlayerProvider>
    <Layout>
      {element}
    </Layout>
  </PlayerProvider>
);

