import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'

const ConsentBanner = () => {
  const [consented, setConsented] = useState(null)

  useEffect(() => {
    const consentStatus = localStorage.getItem('cookie_consent_status')
    setConsented(consentStatus)
    if (consentStatus === 'accepted') {
      handleConsent('accepted')
    }
  }, [])

  const handleConsent = (status) => {
    setConsented(status)
    localStorage.setItem('cookie_consent_status', status)

    if (status === 'accepted') {
        const script = document.createElement('script')
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PRMPY8H823'
        script.async = true
        document.head.appendChild(script)
    
        const initGtag = document.createElement('script')
        initGtag.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-PRMPY8H823');
        `
        document.head.appendChild(initGtag)
    
    
        const clarity = document.createElement('script') 
        clarity.type = "text/javascript"
        clarity.innerHTML = `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "lek62ms7t1");
        `
        clarity.async = true
        document.head.appendChild(clarity)
      }
    }

  if (consented === null) {
    return (
        <div className="fixed bottom-0 left-0 right-0 w-full bg-gray-900/30 backdrop-blur p-4 text-sm [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
          <h2 className="text-white text-lg font-semibold mb-2 mt-0">Cookie Consent</h2>
          <p className="mb-2">We use cookies to enhance your experience, analyze site traffic, and serve personalized content and advertisements. By clicking "Accept", you consent to our use of cookies. You can change your consent at any time in your browser settings. For more information, please see our <Link to="/privacy-policy" className="underline">Privacy Policy</Link>.</p>
          <p className="text-right">
            <button onClick={() => handleConsent('declined')} className="bg-gray-400 rounded-md text-slate-700 p-2 cursor-pointer text-xs">No, thanks</button>
            <button onClick={() => handleConsent('accepted')} className="bg-gray-50 rounded-md text-slate-700 py-2 px-6 cursor-pointer ml-4">Got it</button>
          </p>
        </div>
    )
  }
  return null
}

export default ConsentBanner