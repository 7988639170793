import React from 'react'
import './variables.css'
import './global.css'
import Seo from './seo'
import Navigation from './navigation'
import Footer from './footer'
import Player from './player'
import { Analytics } from "@vercel/analytics/react"

import CookieConsent from './consent-consent';

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Seo />
        <Navigation />
        <main>{children}</main>
        <Footer />
        <Player />
        <CookieConsent />
        <Analytics />
      </>
    )
  }
}

export default Layout

