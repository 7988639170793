import React from 'react';
import { usePlayer } from '../contexts/PlayerContext';
import ReactPlayer from 'react-player/soundcloud'

import * as styles from './player.module.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons"

const buttonClose = <FontAwesomeIcon icon={faRectangleXmark} />

const Player = () => {
    const { isPlaying, SCReady, play, stop, url, isVisible, remove, } = usePlayer();
    if (!isVisible) return null;

    return (
        <div className={styles.MograPlayer}>
            <button className={styles.buttonClose} onClick={()=>{
                    stop()
                    remove()
                }}>
                {buttonClose}</button>
            {url && (
                <ReactPlayer
                    url={url}
                    controls={true}
                    width="100%"
                    height="150"
                    playing={isPlaying}
                    onPause={() => { stop() }}
                    onPlay ={() => { play() }}
                    onReady={() => { SCReady(url) }}
                    config={{
                        soundcloud: {
                          options: { color: "#000000" }
                        }
                    }}
                />
            )}
        </div>
    )
};

export default Player;
