import React, { createContext, useState, useContext } from 'react';

const PlayerContext = createContext();

export const PlayerProvider = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [url, setUrl] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(null);

    const play = () => {
        if(!isVisible) {
            return;
        }
        setIsPlaying(true);
    };
    const SCReady = () => {
        setIsLoading(null);
        play();
    }
    const change = (newUrl) => {
        setUrl(newUrl);
        setIsVisible(true);
    }
    const stop = () => {
        setIsPlaying(false);
    };
    const show = () => {
        setIsVisible(true);
    }
    const remove = () => {
        setIsVisible(false);
        setIsPlaying(false);
        setUrl(null);
    };

    return (
        <PlayerContext.Provider value={{ isPlaying, isVisible, isLoading, setIsLoading, SCReady, play, change, stop, url, remove, show }}>
          {children}
        </PlayerContext.Provider>
    );
};

export const usePlayer = () => useContext(PlayerContext);